import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { getTenant } from "../Tenants.slice";
import { pushToast } from "../../../../components/Toaster/Toaster.slice";
import { getStatementApi, updateInvoiceApi } from '../../../../apis/apis'
import Modal from '../../../../components/Modal'
import InlineEditInput from "../../../../components/Form/InlineEditInput";
import ActionMenu from "../../../../components/ActionMenu";
import Button from "../../../../components/Form/Button";
import Table from "../../../../components/Table/Table";
import Badge from "../../../../components/Badge";
import { formatUTCDate, formatCurrency } from "../../../../util/string";
import { CollectionsInvoice, Paginated } from '../../../../apis/types'
import { useAppDispatch } from "../../../../shared/redux/hooks";

export interface TabInvoicesProps {
  title: string;
  data: Paginated<CollectionsInvoice>;
}

export const TabInvoices: React.FC<TabInvoicesProps> = (props) => {
  const [confirmModal, setConfirmModal] = useState<React.ReactElement | boolean>(false)
  const dispatch = useAppDispatch()

  const { tenantId } = useParams()
  const [currentTenantId, setCurrentTenantId] = useState(tenantId)

  useEffect(() => {
    setCurrentTenantId(tenantId)
  }, [tenantId])

  const handleNextStatusAction = async (record: CollectionsInvoice, status: string): Promise<void> => {
    try {
      await new Promise((resolve, reject) => {
        setConfirmModal(<Modal resolve={resolve} reject={reject} />)
      })
      setConfirmModal(false)
      try {
        await updateInvoiceApi(record.id, { status }) 
        dispatch(
          pushToast({
            type: 'success',
            message: 'Invoice status updated',
          })
        )
        dispatch(getTenant(currentTenantId ?? ''))
      } catch (e: any) {
        dispatch(
          pushToast({
            type: 'error',
            message: 'Error updating invoice status',
            description: `${e.response?.data?.message !== undefined ? `${e.response?.data?.message as string}:` : 'Error:'} ${e.message as string}`,
          })
        )
      }
    } catch (e) {
      setConfirmModal(false)
    }
  }

  const openStatement = async (statementId: string, type: 'king' | 'utility') => {
    try {
      const statementFile = await getStatementApi(statementId, type)
      const statementFileBlob: Blob = new Blob([statementFile], {
        type: 'application/pdf',
      })
      const statementFileUrl: string = window.URL.createObjectURL(statementFileBlob)
      window.open(statementFileUrl, '_blank')
    } catch (e) {
      await dispatch(
        pushToast({
          type: 'error',
          message: 'Error loading statement',
          description: `${
            (e as XMLHttpRequest).response?.data?.message !== undefined ? `${(e as XMLHttpRequest).response?.data?.message as string}:` : 'Error:'
          } ${(e as { message: string }).message}`,
        })
      )
    }
  }

  return (
    <>
      {props.data.results.length > 0 ? (
        <>
          <Table
            colConfig={[
              {
                label: (
                  <>
                    Statement
                    <br />
                    Number
                  </>
                ),
                render: (rec) => rec.number,
              },
              {
                label: (
                  <>
                    Statement
                    <br />
                    Date
                  </>
                ),
                render: (rec) => formatUTCDate(new Date(rec.statementDate)),
              },
              {
                label: (
                  <>
                    Billing
                    <br />
                    Period
                  </>
                ),
                render: (rec) => `${formatUTCDate(new Date(rec.billingStart))} - ${formatUTCDate(new Date(rec.billingEnd))}`,
              },
              {
                label: (
                  <>
                    Due
                    <br />
                    Date
                  </>
                ),
                render: (rec) => formatUTCDate(new Date(rec.due)),
              },
              {
                label: (
                  <>
                    Original
                    <br />
                    Balance
                  </>
                ),
                render: (rec) => formatCurrency(rec.originalBalance),
              },
              {
                label: (
                  <>
                    Amount
                    <br />
                    Due
                  </>
                ),
                render: (rec) => formatCurrency(rec.amountDue),
              },
              {
                label: (
                  <>
                    Payment
                    <br />
                    Method
                  </>
                ),
                render: (rec) => {
                  if (rec.paymentMethod === null) {
                    return null
                  }

                  const { type, providerName, last4 } = rec.paymentMethod
                  return type === 'CHECK' ? 'Check' : `${providerName} (••••${last4})`
                },
              },
              {
                label: 'Memo',
                render: (rec) => (
                  <InlineEditInput
                    name="memo"
                    label="Invoice memo"
                    hideLabel
                    initialValue={rec.memo ?? ''}
                    handleSubmit={async (editedValue) => {
                      await updateInvoiceApi(rec.id, {
                        memo: editedValue,
                      })
                      dispatch(getTenant(currentTenantId ?? ''))
                    }}
                  />
                ),
              },
              {
                label: 'Status',
                render: (rec) => <Badge status={rec.status} />,
              },
              {
                label: 'Change status',
                isHidden: props.data.results.filter((invoice) => invoice.nextStatus?.length > 0).length === 0,
                render: (record) =>
                  record.nextStatus?.length > 0 && (
                    <div>
                      <ActionMenu
                        handleChange={async (status) => await handleNextStatusAction(record, status ?? '')}
                        options={record.nextStatus.map((nextStatus) => ({
                          value: nextStatus,
                        }))}
                      />
                    </div>
                  ),
              },
              {
                label: 'View Statement',
                render: (record) => (
                  <div className="flex flex-row justify-between">
                    <button
                      type="button"
                      onClick={async () => await openStatement(record.id, 'king')}
                      className="inline-flex records-center mr-2 px-2.5 py-1.5 border border-transparent text-sm font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      King
                    </button>
                    {record.externalStatementAvailable && (
                      <button
                        type="button"
                        onClick={async () => await openStatement(record.id, 'utility')}
                        className="inline-flex records-center px-2.5 py-1.5 border border-transparent text-sm font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Utility
                      </button>
                    )}
                  </div>
                ),
              },
            ]}
            data={props.data}
            hidePaginator
          />
          <div className="mt-6">
            <Link to={`/collections/invoices?tenantId=${currentTenantId ?? ''}`}>
              <Button>View all tenant invoices</Button>
            </Link>
          </div>
        </>
      ) : (
        <div>An invoice has not yet been posted for this service account</div>
      )}
      {confirmModal}
    </>
  )
};

export default TabInvoices;
