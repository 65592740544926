import React from 'react'
import DisplayField from '../../../../components/Form/DisplayField'
import Badge from '../../../../components/Badge'
import Panel, { PanelProps } from '../../../../components/Panel'
import { formatUTCDate, formatCurrency } from '../../../../util/string'
import { ServiceAccount } from '../../../../apis/types'

export interface CurrentInvoiceSummaryProps extends Omit<PanelProps, 'header'> {
  serviceAccount: ServiceAccount
}

export const CurrentInvoiceSummary: React.FC<CurrentInvoiceSummaryProps> = (props) => {
  const {
    serviceAccount: {
      currentInvoice: { number, dueDate, amount, remainingBalance, status, billingStartDate, billingEndDate },
    },
    ...rest
  } = props

  return (
    <Panel header="Current invoice" {...rest}>
      <div className="grid grid-cols-1 gap-2">
        <DisplayField label="Invoice number">{number}</DisplayField>
        <DisplayField label="Statement period">{`${formatUTCDate(new Date(billingStartDate))} - ${formatUTCDate(new Date(billingEndDate))}`}</DisplayField>
        <DisplayField label="Due date">{formatUTCDate(new Date(dueDate))}</DisplayField>
        <DisplayField label="Original balance">{formatCurrency(amount)}</DisplayField>
        <DisplayField label="Amount due">{formatCurrency(remainingBalance)}</DisplayField>
        <DisplayField label="Status">
          <Badge status={status} />
        </DisplayField>
      </div>
    </Panel>
  )
}

export default CurrentInvoiceSummary
